
<template>
  <div class="expressNumber">
    <el-dialog
      :title="title"
      :visible.sync="expressNumberShow"
      width="400px"
      :before-close="cancelEvent"
      :close-on-click-modal="false"
    >
      <el-form label-width="105px" label-position="left">
        <el-form-item
          :label="lang_data == 1 ? '快递公司：' : 'Express'"
          size="small"
          required
        >
          <ExpressCompanySelect
            v-model="form.express_company"
            disabled
            style="width: 250px"
            :is-all="false"
          ></ExpressCompanySelect>
        </el-form-item>
        <el-form-item
          :label="lang_data == 1 ? '快递单号：' : 'Tracking No.'"
          required
        >
          <el-input
            v-model="form.express_no"
            :placeholder="lang_data == 1 ? '请输入' : 'Please Enter'"
            clearable
            size="small"
            style="width: 250px"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEvent" size="small">取消</el-button>
        <el-button type="primary" @click="okEvent" size="small">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ExpressCompanySelect } from "@/components/select/index";
export default {
  name: "ExpressNumber",
  props: ["orderId", "express_no", "expressNumberShow", "multiple"],
  components: { ExpressCompanySelect },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      rules: {
        express_no: [
          {
            required: true,
            message:
              localStorage.lang_data == 1 ? "请选择输入快递单号" : "Please Enter",
          },
        ],
      },
      form: {
        id: "",
        order_no: "",
        express_company: "",
        express_no: "",
      },
      title: "",
    };
  },
  mounted() {
    if (this.multiple) {
      this.title =
        this.lang_data == 1
          ? "批量上传快递单号"
          : "Upload the tracking number in bulk";
    } else {
      this.title = this.lang_data == 1 ? "上传快递单号" : "Upload Tracking No.";
    }
  },
  methods: {
    okEvent() {
      if (!this.form.express_company) {
        return this.$message.warning(
          this.lang_data == 1 ? "请选择快递公司" : "Please select"
        );
      } else if (!this.form.express_no) {
        return this.$message.warning(
          this.lang_data == 1 ? "请输入快递单号" : "Please enter"
        );
      }
      let params = {
        express_company: this.form.express_no ? this.form.express_company : "",
        express_no: this.form.express_no,
        order_ids: this.orderId,
      };
      let posturl = "/orders/order_reback/saveExpress";
      this.$axios.post(posturl, params).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.$message.success(res.msg);
          this.$emit("ok-event");
        }
      });
    },
    cancelEvent() {
      this.$emit("cancel-event");
    },
  },
};
</script>
<style lang="scss">
.expressNumber {
  .el-dialog__body {
    min-height: 100px;
  }
}
</style>